import React from 'react';
import loadable from '@loadable/component';
import Spinner from '../atoms/Spinner';

const Loading = () => <Spinner />;

export const Login = loadable(() => import('./LoginPage'), {
  fallback: <Loading />,
});

export const ResetPassword = loadable(() => import('./ResetPasswordPage'), {
  fallback: <Loading />,
});

export const SearchResultCoverSelection = loadable(() => import('./SearchResultCoverSelection'), {
  fallback: <Loading />,
});

export const WorkingCollectionCoverSelection = loadable(() => import('./WorkingCollectionCoverSelection'), {
  fallback: <Loading />,
});

export const SearchResultCollection = loadable(() => import('./SearchResultCollectionPage'), {
  fallback: <Loading />,
});

export const NeuronPage = loadable(() => import('./SearchResultPage'), {
  fallback: <Loading />,
});

export const CollectionView = loadable(() => import('./CollectionViewPage'), {
  fallback: <Loading />,
});

export const CollectionEditCollection = loadable(() => import('./CollectionEditCollectionPage'), {
  fallback: <Loading />,
});

export const CollectionEditCoverSelection = loadable(() => import('./CollectionEditCoverSelection'), {
  fallback: <Loading />,
});

export const ProducerList = loadable(() => import('./ProducerPage'), {
  fallback: <Loading />,
});

export const ProducerDetail = loadable(() => import('./ProducerDetailPage'), {
  fallback: <Loading />,
});

export const ProducerDetailEditPage = loadable(() => import('./ProducerDetailEditPage'), {
  fallback: <Loading />,
});

export const CollectionPreview = loadable(() => import('./CollectionPreviewPage'), {
  fallback: <Loading />,
});

export const UnpublishedCollectionDetail = loadable(() => import('./UnpublishedCollectionDetailPage'), {
  fallback: <Loading />,
});

export const Introduction = loadable(() => import('./IntroductionPage'), {
  fallback: <Loading />,
});

export const Mentoring = loadable(() => import('./MentoringPage'), {
  fallback: <Loading />,
});

export const CreateCell = loadable(() => import('./CreateCellPage'), {
  fallback: <Loading />,
});

export const ScheduledMentoringDetail = loadable(() => import('./ScheduledMentoringDetailPage'), {
  fallback: <Loading />,
});

export const ScheduledMentoringList = loadable(() => import('./ScheduledMentoringListPage'), {
  fallback: <Loading />,
});

export const SuggestedMentoringList = loadable(() => import('./SuggestedMentoringListPage'), {
  fallback: <Loading />,
});

export const MyCellHistory = loadable(() => import('./MyCellHistoryPage'), {
  fallback: <Loading />,
});

export const SuggestedMentoringDetail = loadable(() => import('./SuggestedMentoringDetailPage'), {
  fallback: <Loading />,
});

export const SuggestedMentoringAccept = loadable(() => import('./SuggestedMentoringAcceptPage'), {
  fallback: <Loading />,
});

export const FAQ = loadable(() => import('./FAQPage'), {
  fallback: <Loading />,
});

export const MY = loadable(() => import('./MyPage'), {
  fallback: <Loading />,
});

export const MyFollowList = loadable(() => import('./MyFollowListPage'), {
  fallback: <Loading />,
});

export const ProducerMembershipVerify = loadable(() => import('./ProducerMembershipVerifyPage'), {
  fallback: <Loading />,
});

export const ProducerMembership = loadable(() => import('./ProducerMembershipPage'), {
  fallback: <Loading />,
});

export const Notice = loadable(() => import('./NoticePage'), {
  fallback: <Loading />,
});

export const HomeCollectionList = loadable(() => import('./HomeCollectionListPage'), {
  fallback: <Loading />,
});

export const Privacy = loadable(() => import('./PrivacyPage'), {
  fallback: <Loading />,
});

export const Terms = loadable(() => import('./TermsPage'), {
  fallback: <Loading />,
});

export const ProducerCollectionEdit = loadable(() => import('./CollectionEditCoverSelection'), {
  fallback: <Loading />,
});

export const HomeCollectionCategory = loadable(() => import('./HomeCollectionCategoryPage'), {
  fallback: <Loading />,
});

export const HomeCollectionSearchResult = loadable(() => import('./HomeCollectionSearchResultPage'), {
  fallback: <Loading />,
});

export const DailyIssue = loadable(() => import('./DailyIssueListPage'), {
  fallback: <Loading />,
});

export const CollectionReOrderCell = loadable(() => import('./CollectionReOrderCellPage'), { // 컬렉션에서 셀 순서 조정하기
  fallback: <Loading />,
});

export const CollectionCreateCell = loadable(() => import('./CollectionCreateCellPage'), { // 컬렉션에서 새로운 지식셀 추가하기
  fallback: <Loading />,
});

export const CellSelectCollection = loadable(() => import('./CellSelectCollectionPage'), { // 지식셀 페이지에서 내 컬렉션에 담기
  fallback: <Loading />,
});

export const UserJisikTripList = loadable(() => import('./UserJisikTripList'), { // 유저의 지식 여행기
  fallback: <Loading />,
});

export const PublicMemoListPage = loadable(() => import('./PublicMemoListPage'), {
  fallback: <Loading />,
});

export const PublicMemoCreatePage = loadable(() => import('./PublicMemoCreatePage'), {
  fallback: <Loading />,
});

export const MemoCreatePage = loadable(() => import('./MemoCreatePage'), {
  fallback: <Loading />,
});

export const MemoViewPage = loadable(() => import('./MemoViewPage'), {
  fallback: <Loading />,
});

export const PaymentPage = loadable(() => import('./PaymentPage'), {
  fallback: <Loading />,
});

export const PaymentResultPage = loadable(() => import('./PaymentResultPage'), {
  fallback: <Loading />,
});

export const PaymentDetailPage = loadable(() => import('./PaymentDetailPage'), {
  fallback: <Loading />,
});

export const BannerDetailPage = loadable(() => import('./BannerDetailPage'), {
  fallback: <Loading />,
});

export const BannerListPage = loadable(() => import('./BannerListPage'), {
  fallback: <Loading />,
});

export const YouBookerPage = loadable(() => import('./YouBookerPage'), {
  fallback: <Loading />,
});

export const MyPublishRightPage = loadable(() => import('./MyPublishRightPage'), {
  fallback: <Loading />,
});

export const TogetherNoticeListPage = loadable(() => import('./TogetherNoticeListPage'), {
  fallback: <Loading />,
});

export const MessageListPage = loadable(() => import('./MessageListPage'), {
  fallback: <Loading />,
});

export const CreateTogetherEventPage = loadable(() => import('./CreateTogetherEventPage'), {
  fallback: <Loading />,
});

export const TogetherEventViewPage = loadable(() => import('./TogetherEventViewPage'), {
  fallback: <Loading />,
});

export const PurchaseSettlementPage = loadable(() => import('./PurchaseSettlementPage'), {
  fallback: <Loading />,
});

export const MyPurchaseEventHistoryPage = loadable(() => import('./MyPurchaseEventHistoryPage'), {
  fallback: <Loading />,
});

export const MyAccountInfoPage = loadable(() => import('./MyAccountInfoPage'), {
  fallback: <Loading />,
});

export const MySettlementPage = loadable(() => import('./MySettlementPage'), {
  fallback: <Loading />,
});

export const OwnerAcceptPage = loadable(() => import('./OwnerAcceptPage'), {
  fallback: <Loading />,
});

export const ImportBookPage = loadable(() => import('./ImportBookPage'), {
  fallback: <Loading />,
});

export const Page404 = () => <h1>Not Found</h1>;
