import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { pender } from 'redux-pender';
import * as mentoringAPI from '../../network/api/mentoringApi';
import { getUserRelated } from '../../network/api/privateApi';
import { getGatherInfo, getUserRanking } from '../../network/api/publicApi';

export const storeName = 'mentoring';

// Action Types
const GET_SCHEDULED_MENTORING_LIST = `${storeName}/GET_SCHEDULED_MENTORING_LIST`;
const GET_SUGGESTED_MENTORING_LIST = `${storeName}/GET_SUGGESTED_MENTORING_LIST`;
const GET_USER_RELATED_LIST = `${storeName}/GET_USER_RELATED_LIST`;
const GET_GATHER_LIST = `${storeName}/GET_GATHER_LIST`;
const GET_USER_RANKING_LIST = `${storeName}/GET_USER_RANKING_LIST`;
const SET_YOUBOOKER_TAB = `${storeName}/SET_YOUBOOKER_TAB`;

// Action Creator
export const getScheduledMentoringList = createAction(GET_SCHEDULED_MENTORING_LIST, mentoringAPI.getScheduledMentoringList);
export const getSuggestedMentoringList = createAction(GET_SUGGESTED_MENTORING_LIST, mentoringAPI.getSuggestedMentoringList);
export const getUserRelatedList = createAction(GET_USER_RELATED_LIST, getUserRelated);
export const getGatherList = createAction(GET_GATHER_LIST, getGatherInfo);
export const getUserRankingList = createAction(GET_USER_RANKING_LIST, getUserRanking);
export const setYoubookerTab = createAction(SET_YOUBOOKER_TAB);

// Initial State
const initialState = {
  mentoringListOfMonth: {
    month: '',
    list: [],
  },
  topScheduledMentoringList: [],
  topSuggestedMentoringList: [],
  userRelatedList: [],
  gatherList: [],
  userRankingList: [],
  youbookerTab: 'youbooker',
};

// Reducers
export default handleActions({
  ...pender({
    type: GET_SCHEDULED_MENTORING_LIST,
    onSuccess: (state, action) => produce(state, (draft) => {
      draft.topScheduledMentoringList = action.payload.data.togethers;
    }),
  }),
  ...pender({
    type: GET_SUGGESTED_MENTORING_LIST,
    onSuccess: (state, action) => produce(state, (draft) => {
      draft.topSuggestedMentoringList = action.payload.data.suggestedTogethers;
    }),
  }),
  ...pender({
    type: GET_USER_RELATED_LIST,
    onSuccess: (state, action) => produce(state, (draft) => {
      draft.userRelatedList = action.payload.data.userList;
    }),
  }),
  ...pender({
    type: GET_GATHER_LIST,
    onSuccess: (state, action) => produce(state, (draft) => {
      draft.gatherList = action.payload.data.roomList;
    }),
  }),
  ...pender({
    type: GET_USER_RANKING_LIST,
    onSuccess: (state, action) => produce(state, (draft) => {
      draft.userRankingList = action.payload.data.totalRanks;
    }),
  }),
  [SET_YOUBOOKER_TAB]: (state, action) => produce(state, (draft) => {
    draft.youbookerTab = action.payload;
  }),
}, initialState);
