import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImgCheck from '../../../static/image/checkmark-256.png';

const StyledTopic = styled.div`
  width: 100%;
  font-size: ${(props) => props.containerWidth / (
    ['ko', 'ja', 'zh'].includes(props.language)
      ? 11
      : 12
  )}px;
  letter-spacing: -0.9px;
  ${(props) => (['zh', 'ja', 'th'].includes(props.language) ? 'word-break: break-word;' : 'word-break: keep-all;')}; 
  white-space: break-spaces;
  font-family: ${(props) => (
    props.language === 'ja' ? 'NotoSansJP400, sans-serif'
      : props.language === 'zh' ? 'NotoSansSC400, sans-serif'
        : props.language === 'th' ? 'NotoSansTH400, sans-serif'
          : props.language === 'ar' ? 'Noto Sans Arabic, sans-serif'
            : props.lang === 'vi' ? 'Roboto, sans-serif'
              : 'Noto Sans CJK KR, sans-serif'
  )};
  line-height: ${(props) => props.containerWidth / (
    ['ja', 'ko', 'zh'].includes(props.language)
      ? 8
      : 10
  )}px;
  color: white;
  text-align: center;
  text-shadow:
    1.5px 0 #000000,   
    -1.5px 0 #000000, 
    0 1.5px #000000,  
    0 -1.5px #000000, 
    1.5px 1.5px #000000, 
    1.5px -1.5px #000000,
    -1.5px 1.5px #000000,
    -1.5px -1.5px #000000;
  font-weight: 600;
  
  padding: ${(props) => props.containerWidth / 10}px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const CoverImage = ({ node, onClick, from, borderRadius, isSelected }) => {
  const { imageURL, showTopicOnImage, topic, language, creator } = node || {};

  const containerRef = useRef(null);

  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        const minWidth = width < 91 ? 90 : width < 151 ? 150 : width;
        setContainerWidth(minWidth);
      }
    };

    // DOM이 완전히 렌더링된 후에 초기값 설정
    const timer = setTimeout(updateWidth, 0); // 비동기적으로 처리

    window.addEventListener('resize', updateWidth);

    return () => {
      clearTimeout(timer); // 타이머 정리
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        aspectRatio: '1', // 1:1 비율 설정
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${imageURL || creator?.photo})`,
        backgroundSize: from === 'fullScreen' ? 'contain' : 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        borderRadius: borderRadius && borderRadius,
        zIndex: '3',
        height: from === 'fullScreen' ? '100vh' : 'auto',
        backgroundRepeat: from === 'fullScreen' ? 'no-repeat' : '',
      }}
      onClick={onClick}
    >
      {isSelected && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // 검정 반투명 배경
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={ImgCheck}
            alt="Selected"
            style={{
              width: '40%',
              height: '40%',
            }}
          />
        </div>
      )}
      {showTopicOnImage && (
        <StyledTopic language={language} containerWidth={containerWidth}>
          {topic}
        </StyledTopic>
      )}
    </div>
  );
};

CoverImage.propTypes = {
  node: PropTypes.shape({
    imageURL: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    showTopicOnImage: PropTypes.bool,
    language: PropTypes.string,
  }),
  onClick: PropTypes.func,
  from: PropTypes.string,
  borderRadius: PropTypes.string,
  isSelected: PropTypes.bool,
};

CoverImage.defaultProps = {
  node: {
  },
  onClick: () => {},
  from: '',
  borderRadius: '',
  isSelected: false,
};

export default CoverImage;
