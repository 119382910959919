import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import produce from 'immer';
import * as publicAPI from '../../network/api/publicApi';
import * as privateAPI from '../../network/api/privateApi';

const initialState = {
  totalCount: 0,
  producers: [],
  producerCollection: [],
  producerBookReceived: [],
  producerCell: [],
  pdId: '',
  editCollection: '',
};

const GET_PRODUCER_LIST = 'producer/GET_PRODUCER_LIST';
const GET_PRODUCER_PROFILE = 'producer/GET_PRODUCER_PROFILE';
const REMOVE_COLLECTION = 'producer/REMOVE_COLLECTION';
const UPDATE_PROFILE = 'collection/UPDATE_PRODUCER_PROFILE';
const GET_PRODUCER_COLLECTION = 'producer/GET_PRODUCER_COLLECTION';
const GET_PRODUCER_CELL = 'producer/GET_PRODUCER_CELL';
const UPSERT_PRODUCER_CELL = 'producer/UPSERT_PRODUCER_CELL';
const REMOVE_PRODUCER_CELL = 'producer/REMOVE_PRODUCER_CELL';
const SET_EDIT_COLLECTION = 'producer/SET_EDIT_COLLECTION';

export const getProducerList = createAction(GET_PRODUCER_LIST, publicAPI.getProducerList);
export const getProducerProfile = createAction(GET_PRODUCER_PROFILE, publicAPI.getUserDetailInfo);
export const removeCollection = createAction(REMOVE_COLLECTION, privateAPI.removeCollection, (...meta) => meta);
export const updateProfile = createAction(UPDATE_PROFILE, privateAPI.updateProfile);
export const getProducerCollection = createAction(GET_PRODUCER_COLLECTION, publicAPI.getProducerCollection, (...meta) => meta);
export const getProducerCell = createAction(GET_PRODUCER_CELL, publicAPI.getProducerCell, (...meta) => meta);
export const upsertProducerCell = createAction(UPSERT_PRODUCER_CELL);
export const removeProducerCell = createAction(REMOVE_PRODUCER_CELL);
export const setEditCollection = createAction(SET_EDIT_COLLECTION);

export default handleActions(
  {
    [SET_EDIT_COLLECTION]: (state, action) => produce(state, (draft) => {
      console.log('SET_EDIT_COLLECTION');
      draft.editCollection = action.payload;
    }),
    ...pender({
      type: GET_PRODUCER_LIST,
      onSuccess: (state, action) => {
        const { totalCount, users } = action.payload.data;

        return produce(state, (draft) => {
          draft.totalCount = totalCount;
          draft.producers = users;
        });
      },
    }),
    ...pender({
      type: GET_PRODUCER_PROFILE,
      onSuccess: (state, action) => {
        const { userInfo } = action.payload.data;
        return produce(state, (draft) => {
          const index = state.producers.findIndex((p) => p.id === userInfo.id);
          if (index !== -1) {
            draft.producers[index] = userInfo;
          } else {
            draft.producers.unshift(userInfo);
          }
        });
      },
      onError: (state, action) => {
        console.log('Fetching user information failed with error');
        return state;
      },
    }),
    ...pender({
      type: UPDATE_PROFILE,
      onSuccess: (state, action) => {
        const { user } = action.payload.data;
        return produce(state, (draft) => {
          const index = state.producers.findIndex((p) => p.id === user.id);
          draft.producers[index].introduction = user.introduction;
          draft.producers[index].name = user.name;
          draft.producers[index].photo = user.photo;
          draft.producers[index].profile = user.profile;
          draft.producers[index].mobile = user.mobile;
          draft.producers[index].longIntroduction = user.longIntroduction;
        });
      },
      // onError: (state, action) => produce(state, (draft) => {
      //   draft.producerProfile = '';
      // }),
    }),
    ...pender({
      type: REMOVE_COLLECTION,
      onSuccess: (state, action) => produce(state, (draft) => {
        const id = action.meta[0];
        const owner = draft.producers.find((p) => !!p.collections.find((c) => c.id === id));
        owner.collections = owner.collections.filter((c) => c.id !== id);
      }),
    }),
    ...pender({
      type: GET_PRODUCER_COLLECTION,
      onSuccess: (state, action) => produce(state, (draft) => {
        const id = action.meta[0];
        const type = action.meta[3];
        const isRefresh = action.meta[4];
        const { collections } = action.payload.data;

        // 새롭게 응답받은 컬렉션을 저장하기 위해 이전에 조회했던 프로듀서와 일치하는지 비교
        // 일치한다면 기존 컬렉션에 이어서 붙이기
        // 일치하지 않다면 새로운 컬렉션으로 덮어쓰기, 이전 컬렉션 비워주기

        if (id !== draft.pdId || isRefresh) {
          if (type === 'created') {
            draft.producerCollection = collections;
          } else {
            draft.producerBookReceived = collections;
          }
          draft.producerCell = [];
        } else if (type === 'created') {
          draft.producerCollection = draft.producerCollection.concat(collections);
        } else {
          draft.producerBookReceived = draft.producerBookReceived.concat(collections);
        }

        draft.pdId = id;
      }),
      onError: (state, action, error) => {
        console.log(error);
        return state;
      },
    }),
    ...pender({
      type: GET_PRODUCER_CELL,
      onSuccess: (state, action) => produce(state, (draft) => {
        const id = action.meta[0];
        const { cells } = action.payload.data;
        // 새롭게 응답받은 셀을 저장하기 위해 이전에 조회했던 프로듀서와 일치하는지 비교
        // 일치한다면 기존 셀에 이어서 붙이기
        // 일치하지 않다면 새로운 셀로 덮어쓰기, 이전 셀 비워주기
        if (id !== draft.pdId) {
          draft.producerCell = cells;
          draft.producerCollection = [];
        } else {
          draft.producerCell = draft.producerCell.concat(cells);
        }
        draft.pdId = id;
      }),
      onError: (state, action, error) => {
        console.log(error);
        return state;
      },

    }),
    [UPSERT_PRODUCER_CELL]: (state, action) => produce(state, (draft) => {
      const updatedCell = action.payload;
      const index = state.producerCell.findIndex((c) => c.id == updatedCell.id);
      if (index < 0) {
        draft.producerCell.unshift(updatedCell);
      } else {
        draft.producerCell[index] = {
          ...updatedCell,
          imageURL: `${updatedCell.imageURL}?${(new Date()).getTime()}`,
          updatedAt: (new Date()).getTime(),
        };
      }
    }),
    [REMOVE_PRODUCER_CELL]: (state, action) => produce(state, (draft) => {
      const cellId = action.payload;
      const index = state.producerCell.findIndex((c) => c.id == cellId);
      if (index >= 0) {
        draft.producerCell.splice(index, 1);
      }
    }),
  },
  initialState,
);
